import _objectSpread from"/app/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";import{columnIds as configColumnIds}from"./Config";/**
 * Reorder
 */const reorder=(list,startIndex,endIndex)=>{const result=Array.from(list);const[removed]=result.splice(startIndex,1);result.splice(endIndex,0,removed);return result;};/**
 * With new node ids
 */const withNewNodeIds=(column,nodeIds)=>_objectSpread(_objectSpread({},column),{},{id:column.id,nodeIds});/**
 * Reorder single drag
 */export const reorderSingleDrag=_ref=>{let{entities,selectedNodeIds,source,destination}=_ref;if(source.droppableId===destination.droppableId){const column=entities.columns[source.droppableId];const reordered=reorder(column.nodeIds,source.index,destination.index);const updated=_objectSpread(_objectSpread({},entities),{},{columns:_objectSpread(_objectSpread({},entities.columns),{},{[column.id]:withNewNodeIds(column,reordered)})});return{entities:updated,selectedNodeIds};}return{entities,selectedNodeIds};};/**
 * Update Entities
 */export const entitiesUpdate=(entities,record,columnId)=>{const columnIds=configColumnIds;const nodes=[...entities.nodes];if(!columnIds.includes(columnId)){throw new Error("Invalid columnId: ".concat(columnId));}const currentCatIndex=Number(columnId[3]);// Reset nodeIds for columns starting from the current category
const resetColumns=columnIds.reduce((accumulator,currentColumnId)=>{const colNumber=Number(currentColumnId[3]);// Extract the number from CATx
if(colNumber!==1&&colNumber>=currentCatIndex){accumulator[currentColumnId]=_objectSpread(_objectSpread({},entities.columns[currentColumnId]),{},{nodeIds:[]// Clear nodeIds for columns from currentCatIndex onwards
});}else{accumulator[currentColumnId]=_objectSpread({},entities.columns[currentColumnId]);// Keep others as is
}return accumulator;},{});const columns=_objectSpread({},resetColumns);if(record.optionNodeIds){for(const nodeId of record.optionNodeIds){var _item$conditionNode;const item=entities.nodes.find(node=>node.id===nodeId);// Only add active nodes
if(item&&(_item$conditionNode=item.conditionNode)!==null&&_item$conditionNode!==void 0&&_item$conditionNode.isActive){// Add node ID to the specified column
columns[columnId].nodeIds.push(nodeId);}}}return _objectSpread(_objectSpread({},entities),{},{nodes,columnIds,columns});};const normalizeName=name=>name.replace(/\s+/g,"").toLowerCase();export const hasDuplicateNameInCategory=(currentNodeId,columnId,name,entities)=>{const normalizedName=normalizeName(name);return entities.nodes.some(node=>{return node.columnId===columnId&&node.id!==currentNodeId&&normalizeName(node.name)===normalizedName;});};